import React, { useEffect, useRef, useState } from 'react';
import FormDialogModal from '../../components/FormDialogModal';
import { FicInput } from '../../Utils/FormInputComponents';
import DataTableWSearch from '../../components/DataTableWSearch';
import * as yup from "yup"
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import GenBtn from '../../components/GenBtn';
import * as Fa from 'react-icons/fa';

const schema = yup.object({
    descripcion: yup.string().required('Tipo de gasto es requerido'),
})


function TipoGastoList(props) {

    const { handleSubmit, reset, control, formState: { errors }} = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            descripcion: '',
        }    
    })
    const { handleSubmit: editSubmit, reset: editReset, control: editControl, formState: { errors: editErrors }} = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            descripcion: "",
        }    
    })

    const {crearTipoGasto, listarGastos, editarTipoGasto, data } = props;
    const [ descripcionFieldData, setDescripcionFieldData ] = useState()
    const [ gastoId, setGastoId ] = useState()

    const dialogRef = useRef(null)
    const editDialogRef = useRef(null)
    
    async function onSubmit(formData) {
        await crearTipoGasto(formData);
        reset();
        listarGastos();
    }

    async function onEditSubmit(formData) {
        await editarTipoGasto(formData, gastoId);
        listarGastos();
        editDialogRef.current.close()
        editReset();
    }

    useEffect(() => {
        listarGastos();
    }, [])

    const columns = [
        {
            name: 'Tipo de gasto',
            selector: row => row.descripcion,
            sortable: true,
        },
        {
            cell: (row) => <GenBtn key={row.nombre} textColor="hsl(0, 0%, 80%)" background="hsla(0, 100%, 100%, 0.2)" 
                                onPress={
                                (e) => {
                                    editDialogRef.current.showModal() 
                                    setDescripcionFieldData(row.descripcion)
                                    setGastoId(row.id)
                                }}>
                                <Fa.FaPen/>
                            </GenBtn>
                ,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return(
        <React.Fragment>

            <br />
            <h3 className="p-2  title rounded shadow"
                style={{border: "1px solid #790021", background: "linear-gradient(to top right, #790021, #2d2d2d)"}}
            >
                Tipos de gasto
            </h3>
            <br />
            <div className="d-flex flex-row justify-content-start mb-2">
            <GenBtn
                onClick={() => {
                    dialogRef.current.showModal()
                    }}>
                Agregar
            </GenBtn>

            </div>

            <DataTableWSearch 
                columns={columns} 
                data={data} 
                fieldToFilter="Tipo de gasto" 
                filterPlaceHolder="Buscar por tipo de gasto"
            />

            <FormDialogModal dialogRef={dialogRef} onSubmit={handleSubmit(onSubmit)} title={"Agregar tipo de gasto"}>
                <div style={{display:"flex", flexDirection: "column", gap: "15px", width:"25rem", height:"10rem", padding: "15px"}}>
                    <FicInput
                        name="descripcion"
                        placeholder="Tipo de gasto"
                        control={control}
                        error={errors && errors.descripcion && errors.descripcion.message}                   
                    />
                    <div className='d-flex justify-content-end mt-auto'>
                        <GenBtn 
                            type="submit"
                            style={{
                                width:"90px", 
                                height:"40px", 
                                marginBottom:"8px"}}>
                            Agregar
                        </GenBtn>
                    </div>
                </div>

            </FormDialogModal>

            <FormDialogModal dialogRef={editDialogRef} onSubmit={editSubmit(onEditSubmit)} title={"Modificar tipo de gasto"}>
                <div style={{display:"flex", flexDirection: "column", gap: "15px", width:"25rem", height:"10rem", padding: "15px"}}>
                    <FicInput
                        name="descripcion"
                        placeholder={`${descripcionFieldData}`}
                        control={editControl}
                        error={editErrors && editErrors.descripcion && editErrors.descripcion.message}
                    />
                    <div className='d-flex justify-content-end mt-auto'>
                        <GenBtn 
                            type="submit"
                            style={{
                                width:"90px", 
                                height:"40px", 
                                marginBottom:"8px"}}>
                            Guardar
                        </GenBtn>
                    </div>
                </div>

            </FormDialogModal>
            
        </React.Fragment>
    );

}

export default TipoGastoList;

