import { handleActions, createAction } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const ListarProveedorAction = createAction("LISTAR_PROVEEDORES")

export const crearProveedor = (data) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const formData = {
        empresa: id_emp[5],
        nombre: data.nombre,
        telefono: data.telefono,
        correo: data.correo,
        contacto: data.contacto
    }
    return api.post('auto_ventas/proveedor', formData).then(() => {
        NotificationManager.success(
            'Proveedor creado',
            'Exito',
            3000
        );
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    })
}

export const editarProveedor = (data, proveedorId) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const formData = {
        empresa: id_emp[5],
        nombre: data.nombre,
        telefono: data.telefono,
        correo: data.correo,
        contacto: data.contacto
    }
    return api.put(`auto_ventas/proveedor/${proveedorId}`, formData).then(() => {
        NotificationManager.success(
            'Proveedor modificado',
            'Exito',
            3000
        );
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    })
}

export const listarProveedores = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    return api.get('auto_ventas/proveedor', params).then(response => {
        dispatch(ListarProveedorAction(response));
    }).catch((error) => {
        return [];
    })
}

export const actions = {
    crearProveedor,
    listarProveedores,
    editarProveedor
};

export const reducers = {
    [ListarProveedorAction]: (state, action) => {
        return {
            ...state,
            data: action.payload
        }
    },
};

export const initialState = {
    data: [],
};

export default handleActions(reducers, initialState)