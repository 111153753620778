import { NotificationManager } from "react-notifications";
import { api } from "api";
import { handleActions } from 'redux-actions';
import { listarIngresos } from "./ingresos";
import { listarMarcas } from "./ingresos";
import { listarCombustibles } from "./ingresos";
import { listarLineas } from "./ingresos";
import { listarTipos } from "./ingresos";
import { listarProveedores } from "./ingresos";

const PARAMETRO = 'PARAMETRO';

export const datosDelIngreso = (ingresoId) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let action = "editar"
    const params = { id, action }
    return api.get(`auto_ventas/ingresos/${ingresoId}`, params).then(response => {
        return response;
    }).catch(() => {
        NotificationManager.error(
            "Ocurrio un error al obtener los datos",
            'Error',
            2000,
            null,
            true
        );
        return [];
    })
}

export const actualizarIngreso = (data,ingresoId) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    
    data.combustible = data.combustible ? data.combustible.value : ""
    data.linea = data.linea.value
    data.marca = data.marca.value
    data.tipo_carro = data.tipo_carro ? data.tipo_carro.value : ""
    data.empresa = id 
    data.fecha_ingreso = data.fecha_ingreso
    data.proveedor = data.proveedor ? data.proveedor.value : ""
    data.nombre_valuador = data.nombre_valuador
    if (typeof data.foto === 'string') {
        data.foto = undefined;
    }
    
    return api.putAttachments(`auto_ventas/ingresos/${ingresoId}`, {...data, foto: null}, [{ "name": 'foto', "file": data.foto}]).then(() => {
            NotificationManager.success(
                'Vehiculo Modificado',
                'Exito',
                3000,
                null,
                true
            );
            return true;
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000,
                null,
                true
            );
            return false;
        })


}

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id}
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
    }).catch(()=>{
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    })
}

export const actions = {
    listarMarcas,
    listarLineas,
    listarCombustibles,
    listarTipos,
    listarIngresos,
    datosDelIngreso,
    actualizarIngreso,
    parametrosEmpresa,
    listarProveedores
};

export const reducers = {
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
}

export const initialState = {
    datos: []
};

export default handleActions(reducers, initialState)