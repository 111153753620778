import React, { useEffect } from 'react';
import Formulario from './IngresosForm';

function Ingresos(props) {
    
    const { 
        listarMarcas,
        listarLineas,
        listarCombustibles,
        listarTipos,
        crearIngreso,
        reporteRecepcionDeVehiculos,
        listarProveedores,
        parametrosEmpresa,
        datos
    } = props;

    useEffect(() => {
        parametrosEmpresa();
    }, []);


    return(
        <Formulario
            listarMarcas = {listarMarcas}
            listarLineas = {listarLineas}
            listarCombustibles = {listarCombustibles}
            listarTipos = {listarTipos}
            crearIngreso = {crearIngreso}
            listarProveedores = {listarProveedores}
            reporteRecepcionDeVehiculos = {reporteRecepcionDeVehiculos}
            datos = {datos}
        />
    )
}


export default Ingresos;