import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';

import TablaVerCC from './TablaPolizaVerCentroCosto';
import TablaVerSCC from './TablaPolizaVerSCentroCosto';
import { validate, validators } from 'validate-redux-form';
import PropTypes from 'prop-types';
import Modal from '../../components/ModalPoliza';
import ModalCuenta from '../../components/Modal';
import styled from 'styled-components';
import Tabla from './TablaPolizaSCentroCosto';
import TablaCC from './TablaPolizaCentroCosto';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import NavbarModulo from '../../components/NavbarModulo';

import {
  renderField,
  renderDayPickerB,
  SelectField,
  AsyncSelectField,
  renderCurrencyPoliza,
  renderNumber
} from "../../Utils/renderField/renderField";



const tipo = [
  { label: "DIARIO", value: "DR" },
  { label: "BANCOS", value: "BAN" },
  { label: "CUENTAS POR COBRAR", value: "CXC" },
  { label: "CUENTAS POR PAGAR", value: "CXP" },
  { label: "AJUSTE", value: "AJ" },
  { label: "CIERRE FISCAL", value: "CIE" },
];

function PolizasForm(props) {
  const {
    funcion,
    listarCuentas,
    crear, lectura,
    listarCC,
    registroEnc,
    detallePoliza,
    eliminarLinea,
    registroSCC,
    niveles,
    registroCuenta,
    reporteCuentas,
    loaderC,
    modulo,
    periodoD,
    datos
  } = props;

  const [estadoModal, cambiarEstadoModal] = useState(false);
  const [estadoModalCC, cambiarEstadoModalCC] = useState(false);
  const [estadoModalCuenta, cambiarEstadoModalCuenta] = useState(false);

  const editar = window.location.href.includes('editar');
  let titulo = editar ? 'Modificar Póliza' : 'Nueva Póliza';
  let disabled = false;
  if (crear === false && editar === false) {
    disabled = true;
    if (lectura) {
      titulo = 'Póliza' + ' - ' + lectura.numero_poliza
    }

  }

  let autoFocus = true;
  localStorage.setItem('tituloForm', titulo);
  /* crear cuenta contable*/
  const getNivel = () => {
    let formato = ''
    if (niveles.length > 0) {
      for (let a = 0; a < niveles[0].nivel_uno; a++) {
        formato = formato + '#'
      }
      for (let b = 0; b < niveles[0].nivel_dos; b++) {
        if (b === 0) {
          formato = formato + '-' + '#'
        } else {
          formato = formato + '#'
        }
      }
      for (let c = 0; c < niveles[0].nivel_tres; c++) {
        if (c === 0) {
          formato = formato + '-' + '#'
        } else {
          formato = formato + '#'
        }
      }
      for (let d = 0; d < niveles[0].nivel_cuatro; d++) {
        if (d === 0) {
          formato = formato + '-' + '#';
        } else {
          formato = formato + '#';
        }
      }
      for (let e = 0; e < niveles[0].nivel_cinco; e++) {
        if (e === 0) {
          formato = formato + '-' + '#';
        } else {
          formato = formato + '#';
        }
      }
      for (let f = 0; f < niveles[0].nivel_seis; f++) {
        formato = formato + '#';
      }
      localStorage.setItem('formato', formato.toString());
    }

    return formato
  };

  const tipos = [
    {
      value: 'A',
      label: 'Acumulativa'
    },
    {
      value: 'D',
      label: 'Detalle'
    },
  ];

  const naturaleza = [
    {
      value: 'ACTIVO',
      label: 'Activo'
    },
    {
      value: 'PASIVO',
      label: 'Pasivo'
    },
    {
      value: 'CAPITAL',
      label: 'Capital'
    },
    {
      value: 'INGRESOS',
      label: 'Ingresos'
    },
    {
      value: 'COSTOS',
      label: 'Costos'
    },
    {
      value: 'GASTOS',
      label: 'Gastos'
    },
  ];
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let dif_poliza = localStorage.getItem('dif');
  
  return (
    <div>
      <NavbarModulo
        periodoD={periodoD}
        modulo={modulo}
        titulo={titulo}
      />
      <br />
      <div className="mb-4 card card-small bg-dark mb-3">
        <div className="p-0 pt-3 d-flex flex-column flex-md-row">
          <div className="d-flex flex-column flex-1 mx-3">
            <div className='row mb-2'>
              <div className='col-4'>
                <Field
                  autoFocus={autoFocus}
                  disabled={disabled}
                  name="fecha"
                  component={renderDayPickerB}
                  msj="Fecha"
                />
              </div>
              <div className='col-4'>
                <div className="form-floating">
                  <Field
                    name="tipo"
                    options={tipo}
                    crear={crear}
                    dato={crear === true ? 0 : lectura.tipo}
                    component={SelectField}
                    className="form-select"
                    disabled={disabled}
                    msj="Tipo poliza"
                  />
                </div>
              </div>
              <div className='col-4'>
                <Field
                  name='numero_poliza'
                  component={renderField}
                  className="form-control"
                  placeholder="No de poliza"
                  disabled={true}
                  msj="No de poliza"
                />
              </div>
            </div>
            <div className='mb-2'>
              <Field
                id="descripcion"
                name="descripcion"
                placeholder="Descripcion"
                component={renderField}
                className="form-control"
                disabled={disabled}
                msj="Descripcion"

              />
            </div>
            {lectura.tipo === 'BAN' && lectura.tipoD === 'P' && crear === false && editar === false &&
              <div>
                <h4 className="p-2 bg-secondary title">Documento</h4>
                <div className='row mb-2'>
                  <div className='col-4'>
                    <Field
                      name="numero"
                      placeholder="Numero"
                      component={renderField}
                      className="form-control"
                      disabled={disabled}
                      msj="Numero"
                    />
                  </div>
                  <div className='col-4'>
                    <Field
                      name="tipoDoc"
                      placeholder="Tipo"
                      component={renderField}
                      className="form-control"
                      disabled={disabled}
                      msj="Tipo"
                    />
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col-12'>
                    <Field
                      name="proveedorN"
                      placeholder="Proveedor"
                      component={renderField}
                      className="form-control"
                      disabled={disabled}
                      msj="Proveedor"
                    />
                  </div>
                </div>
              </div>
            }
            {lectura.tipo === 'BAN' && lectura.tipoD === 'C' && crear === false && editar === false &&
              <div>
                <h4 className="p-2 bg-secondary title">Documento</h4>
                <div className='row mb-2'>
                  <div className='col-4'>
                    <Field
                      name="numero"
                      placeholder="Numero"
                      component={renderField}
                      className="form-control"
                      disabled={disabled}
                      msj="Numero"
                    />
                  </div>
                  <div className='col-4'>
                    <Field
                      name="tipoDoc"
                      placeholder="Tipo"
                      component={renderField}
                      className="form-control"
                      disabled={disabled}
                      msj="Tipo"
                    />
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col-12'>
                    <Field
                      name="clienteN"
                      placeholder="Cliente"
                      component={renderField}
                      className="form-control"
                      disabled={disabled}
                      msj="Cliente"
                    />
                  </div>
                </div>
              </div>
            }
            {lectura.tipo === 'CXP' && crear === false && editar === false &&
              <div>
                <h4 className="p-2 bg-secondary title">Documento</h4>
                {lectura.declaracion &&
                  <div className='row mb-2'>
                    <div className='col-4'>
                      <Field
                        name="declaracion"
                        placeholder="Declaración"
                        component={renderField}
                        className="form-control"
                        disabled={disabled}
                        msj="Declaración"
                      />
                    </div>
                  </div>
                }
                <div className='row mb-2'>
                  <div className='col-4'>
                    <Field
                      name="serie"
                      placeholder="Serie"
                      component={renderField}
                      className="form-control"
                      disabled={disabled}
                      msj="Serie"
                    />
                  </div>
                  <div className='col-4'>
                    <Field
                      name="numero"
                      placeholder="Numero"
                      component={renderField}
                      className="form-control"
                      disabled={disabled}
                      msj="Numero"
                    />
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col-12'>
                    <Field
                      name="proveedorN"
                      placeholder="Proveedor"
                      component={renderField}
                      className="form-control"
                      disabled={disabled}
                      msj="Proveedor"
                    />
                  </div>
                </div>
              </div>
            }
            {lectura.tipo === 'CXC' && crear === false && editar === false &&
              <div>
                <h4 className="p-2 bg-secondary title">Documento</h4>
                <div className='row mb-2'>
                  <div className='col-4'>
                    <Field
                      name="serie"
                      placeholder="Serie"
                      component={renderField}
                      className="form-control"
                      disabled={disabled}
                      msj="Serie"
                    />
                  </div>
                  <div className='col-4'>
                    <Field
                      name="numero"
                      placeholder="Numero"
                      component={renderField}
                      className="form-control"
                      disabled={disabled}
                      msj="Numero"
                    />
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col-12'>
                    <Field
                      name="clienteN"
                      placeholder="Cliente"
                      component={renderField}
                      className="form-control"
                      disabled={disabled}
                      msj="Cliente"
                    />
                  </div>
                </div>
              </div>
            }
            {disabled == false && datos.centro_costo === 'N' &&
              <Contenido>
                <button
                  className='btn mr-2 mb-3 btn-primary'
                  onClick={() => cambiarEstadoModal(!estadoModal)}
                >
                  Agregar Linea
                </button>
              </Contenido>
            }

            {disabled == false && datos.centro_costo === 'S' &&
              <Contenido>
                <button
                  className='btn mr-2 mb-3 btn-primary'
                  onClick={() => cambiarEstadoModalCC(!estadoModalCC)}
                >
                  Agregar Linea
                </button>
              </Contenido>
            }

            {crear === false && editar === false ? (
              lectura.polizaDetalle_polizaEncabezado.length > 0 &&
              (datos.centro_costo === 'S' ? (
                <TablaVerCC
                  lectura={lectura}
                />
              ) : (
                <TablaVerSCC
                  lectura={lectura}
                />
              ))
            ) : (crear === false && editar === true ? (
              lectura.polizaDetalle_polizaEncabezado &&
              (datos.centro_costo === 'S' ? (
                <TablaCC
                  detallePoliza={lectura.polizaDetalle_polizaEncabezado}
                  eliminarLinea={eliminarLinea}
                />

              ) : (
                <Tabla
                  detallePoliza={lectura.polizaDetalle_polizaEncabezado}
                  eliminarLinea={eliminarLinea}
                />
              ))
            ) : (datos.centro_costo === 'S' ? (
              <TablaCC
                detallePoliza={detallePoliza}
                eliminarLinea={eliminarLinea}
              />
            ) : (
              <Tabla
                detallePoliza={detallePoliza}
                eliminarLinea={eliminarLinea}
              />
            )
            ))
            }
            <br />
            <div className='d-flex flex-row mt-3'>
              {disabled == false &&
                <button
                  className='btn mr-2 mb-3 btn-primary'
                  onClick={funcion}
                >
                  {editar ? 'Modificar' : 'Guardar'}
                </button>
              }
              {id_emp[6] === 'polizas' &&
                <a
                  href={`/#/contabilidad/${id_emp[5]}/polizas/${id_emp[7]}`}
                  className='btn btn-secondary mb-3'
                >
                Regresar
                </a>
              }
              {id_emp[6] === 'poliza_parqueo_diario' &&
                <a
                  href={`/#/facturacion/${id_emp[5]}/poliza_parqueo/${id_emp[7]}`}
                  className='btn btn-secondary mb-3'
                >
                Regresar
                </a>
              }
            </div>
            <Modal
              estado={estadoModal}
              cambiarEstado={cambiarEstadoModal}
              titulo="Ingresar Detalle poliza"
              botonCerrar="true"
            >
              <div className='row mb-3'>
                <div className='col-6'>
                  <Field
                    name="cuenta"
                    placeholder="Cuenta contable..."
                    className="form-control-find"
                    component={AsyncSelectField}
                    loadOptions={listarCuentas}
                    msj="Cuenta"
                  />
                  <button
                    className='btn ml-2 btn-dark'
                    onClick={() => cambiarEstadoModalCuenta(!estadoModalCuenta)}
                  >
                    Crear cuenta
                  </button>
                </div>
                <div className='col-3 mt-1 w-25'>
                  <Field
                    name='debe'
                    component={renderCurrencyPoliza}
                    className="form-control"
                    placeholder="Debe"
                    msj="Debe"
                  />
                </div>
                <div className='col-3 mt-1'>
                  <Field
                    name='haber'
                    component={renderCurrencyPoliza}
                    className="form-control"
                    placeholder="Haber"
                    msj="Haber"
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-9'>
                </div>
                <div className='col-3 title'>
                  Diferencia: {dif_poliza}
                </div>
              </div>
              <Contenido>
                <div className='row'>
                  <div className='col-6'>
                    <button
                      className='btn ml-3 mb-3 btn-primary'
                      onClick={() => registroSCC(periodoD)}
                    >
                      Agregar
                    </button>
                  </div>
                  <div className='col-6'>
                    <button
                      className='btn mb-3 btn btn-secondary'
                      onClick={() => cambiarEstadoModal(false)}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </Contenido>
            </Modal>
            <Modal
              estado={estadoModalCC}
              cambiarEstado={cambiarEstadoModalCC}
              titulo="Ingresar Detalle poliza"
              botonCerrar="true"
            >
              <div className='row mb-3'>
                <div className='col-6'>
                  <Field
                    autoFocus={true}
                    name="cuenta"
                    placeholder="Cuenta contable..."
                    className="form-control-find"
                    component={AsyncSelectField}
                    loadOptions={listarCuentas}
                    msj="Cuenta"
                  />
                  <button
                    className='btn ml-2 btn-dark'
                    onClick={() => cambiarEstadoModalCuenta(!estadoModalCuenta)}
                  >
                    Crear cuenta
                  </button>
                </div>

                <div className='col-2'>
                  <Field
                    name="cc"
                    placeholder="C.C..."
                    className="form-control-find"
                    component={AsyncSelectField}
                    loadOptions={listarCC}
                    msj="Centro Costo"
                  />
                </div>
                <div className='col-2 mt-1 w-25'>
                  <Field
                    name='debe'
                    component={renderCurrencyPoliza}
                    className="form-control"
                    placeholder="Debe"
                    msj="Debe"
                  />
                </div>
                <div className='col-2 mt-1'>
                  <Field
                    name='haber'
                    component={renderCurrencyPoliza}
                    className="form-control"
                    placeholder="Haber"
                    msj="Haber"
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-9'>
                </div>
                <div className='col-3 title'>
                  Diferencia: {dif_poliza}
                </div>
              </div>
              <Contenido>
                <div className='row'>
                  <div className='col-6'>
                    <button
                      className='btn ml-3 mb-3 btn-primary'
                      onClick={() => registroEnc(periodoD)}
                    >
                      Agregar
                    </button>
                  </div>
                  <div className='col-6'>
                    <button
                      className='btn mr-2 mb-3 btn btn-secondary'
                      onClick={() => cambiarEstadoModalCC(false)}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </Contenido>
            </Modal>
            <ModalCuenta
              estado={estadoModalCuenta}
              cambiarEstado={cambiarEstadoModalCuenta}
              titulo="Crear cuenta contable"
              botonCerrar="false"
            >
              <div className="d-flex flex-column flex-1 mx-4">
                <div className='mb-2 w-50'>
                  <Field
                    autoFocus={autoFocus}
                    type="number"
                    id="cuenta"
                    name="cuenta"
                    placeholder="Cuenta"
                    className="form-control"
                    numberFormat={getNivel()}
                    component={renderNumber}
                    msj="Cuenta"
                  />
                </div>
                <div className='mb-2 w-100'>
                  <Field
                    id="nombre"
                    name="nombre"
                    placeholder="Nombre Cuenta"
                    className="form-control"
                    component={renderField}
                    msj="Nombre Cuenta"
                  />
                </div>
                <div className='mb-2 w-50'>
                  <div className="form-floating">
                    <Field
                      name="tipoC"
                      options={tipos}
                      crear={crear}
                      dato={crear === true ? 0 : lectura.tipo}
                      component={SelectField}
                      className="form-select"
                      msj="Tipo"
                    />
                  </div>
                </div>
                <div className='mb-3 w-50'>
                  <div className="form-floating">
                    <Field
                      id="naturaleza"
                      name="naturaleza"
                      options={naturaleza}
                      crear={crear}
                      dato={crear === true ? 0 : lectura.naturaleza}
                      component={SelectField}
                      className="form-select"
                      msj="Naturaleza"
                    />
                  </div>
                </div>
                <LoadMask loading={loaderC} dark>
                  <Contenido>
                    <div className='row'>
                      <button
                        className='btn mr-2 mb-3 btn-primary'
                        onClick={() => registroCuenta(getNivel())}
                      >
                        Guardar
                      </button>
                      <button
                        className='btn mr-2 mb-3 btn-dark'
                        onClick={reporteCuentas}
                      >
                        Generar reporte
                      </button>
                      <button
                        className='btn mr-2 mb-3 btn-secondary'
                        onClick={() => cambiarEstadoModalCuenta(false)}
                      >
                        Cancelar
                      </button>
                    </div>
                  </Contenido>
                </LoadMask>
              </div>
            </ModalCuenta>
          </div>
        </div>
      </div>
    </div>
  );

}

PolizasForm.propTypes = {
  funcion: PropTypes.func,
  listarCuentas: PropTypes.func,
  crear: PropTypes.bool,
  lectura: PropTypes.object,
  listarCC: PropTypes.func,
  registroEnc: PropTypes.func,
  detallePoliza: PropTypes.object,
  eliminarLinea: PropTypes.func,
  registroSCC: PropTypes.func,
  niveles: PropTypes.func,
  registroCuenta: PropTypes.func,
  reporteCuentas: PropTypes.func,
  loaderC: PropTypes.bool,
  modulo: PropTypes.object,
  datos: PropTypes.object,
  periodoD: PropTypes.object,
};


export default reduxForm({
  form: 'FormPolizas',
  validate: (data) => {
    return validate(data, {
      fecha: validators.exists()('Este campo es requerido'),
      tipo: validators.exists()('Seleccione tipo'),
    });
  },
})(PolizasForm);

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;

