import React, { Component } from 'react';
import Formulario from './InformesForm';

class Informes extends Component{
    componentDidMount = () => {
        const { getModulo, listarMonedas } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
        listarMonedas();
    }
    render(){
        const { 
            loader, 
            modulo, 
            reporteKardexValorizado, 
            listarProductos, 
            listarClientes,
            listarFases, 
            listarSeries, 
            listarSeriesProd, 
            reporteProduccionDetallePro, 
            reporteProduccionTrazPro, 
            excelReporteProduccionDetalle, 
            reporteConsumos,
            reporteIngresosProdDetalle, 
            listarBodegas,
            listarPilotos,
            reporteControlCajasPiloto,
            listarInsumos,
            reporteExistenciaCajas,
            excelReporteConsumos,
            listarSeriesNotaCobro,
            reporteListadoNotasDeCobro,
            reporteExistenciaCajasExcel,
            reporteKardexCajas,
            reporteCosechas,
            reporteCXCDetalleEnvio,
            loaderC,
            monedas,
            listarPeriodos,
            listaPeriodo,
            reporteCostoLibra,
            excelReporteControlCajasPiloto,
            reporteVentaFase
        } = this.props;
        return(
            <div>
                <Formulario
                    loader = {loader}
                    loaderC = {loaderC}
                    modulo = {modulo}
                    listarProductos = {listarProductos}
                    reporteKardexValorizado = {reporteKardexValorizado}
                    reporteIngresosProdDetalle = {reporteIngresosProdDetalle}
                    listarFases = {listarFases}
                    reporteProduccionDetallePro = {reporteProduccionDetallePro}
                    reporteProduccionTrazPro = {reporteProduccionTrazPro}
                    listarClientes = {listarClientes}
                    excelReporteProduccionDetalle = {excelReporteProduccionDetalle}
                    reporteConsumos = {reporteConsumos}
                    listarSeries = {listarSeries}
                    listarSeriesProd = {listarSeriesProd}
                    listarBodegas = {listarBodegas}
                    listarPilotos = {listarPilotos}
                    reporteControlCajasPiloto = {reporteControlCajasPiloto}
                    listarInsumos = {listarInsumos}
                    reporteExistenciaCajas = {reporteExistenciaCajas}
                    excelReporteConsumos = {excelReporteConsumos}
                    listarSeriesNotaCobro = {listarSeriesNotaCobro}
                    reporteListadoNotasDeCobro = {reporteListadoNotasDeCobro}
                    reporteExistenciaCajasExcel = {reporteExistenciaCajasExcel}
                    reporteKardexCajas = {reporteKardexCajas}
                    reporteCosechas = {reporteCosechas}
                    reporteCXCDetalleEnvio = {reporteCXCDetalleEnvio}
                    monedas = {monedas}
                    listarPeriodos = {listarPeriodos}
                    listaPeriodo = {listaPeriodo}
                    reporteCostoLibra = {reporteCostoLibra}
                    excelReporteControlCajasPiloto = {excelReporteControlCajasPiloto}
                    reporteVentaFase = {reporteVentaFase}
                />
            </div>
        );
    }
}

export default Informes;
