import { NotificationManager } from "react-notifications";
import { handleActions } from 'redux-actions';
import { api } from "api";

const PARAMETRO = 'PARAMETRO';

export const datosDelIngreso = (ingresoId) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let action = "ver"
    const params = { id, action }
    return api.get(`auto_ventas/ingresos/${ingresoId}`, params).then(response => {
        return response;
    }).catch(() => {
        NotificationManager.error(
            "Ocurrio un error al obtener los datos",
            'Error',
            2000,
            null,
            true
        );
        return [];
    })
}

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id}
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
    }).catch(()=>{
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    })
}

export const actions = {
    datosDelIngreso,
    parametrosEmpresa
};

export const reducers = {
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
};

export const initialState = {
    datos: []
};

export default handleActions(reducers, initialState)