import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Radio } from "react-aria-components";
import {
    FicImageDrop,
    FicInput,
    FicRadio,
    FicCheckbox,
    FicTextArea,
    FicNumber,
    FicDatePicker
} from "../../Utils/FormInputComponents/index";
import { useEffect } from "react";
import "../../../../../style/autoVentas/IngresosForm.css";
import GenBtn from "../../components/GenBtn";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import { withRouter } from "react-router";

function IngresosFormVer(props) {
    const { datosDelIngreso, history, parametrosEmpresa, datos } = props;
    const urlParams = props.match.params;
    const ingresoId = urlParams.ingresoId;
    const empresa = urlParams.empresa;
    const [loadingState, setLoadingState] = useState(true);

    const {
        handleSubmit,
        control,
        formState: { },
        watch,
    } = useForm({
        mode: "all",
        defaultValues: async () => {
            const formData = await datosDelIngreso(ingresoId);
            setLoadingState(false);
            return formData;
        },
    });

    const modalidad = watch("modalidad");

    useEffect(() => {
        parametrosEmpresa()
    }, []);

    return (
        <React.Fragment>
            <br />
            <h3
                className="p-2  title rounded shadow"
                style={{
                    background:
                        "linear-gradient(to top right, #790021, #2d2d2d)",
                }}
            >
                Ver ingreso
            </h3>
            <br />
            <div className="d-flex flex-row justify-content-start mb-2">
                <GenBtn
                    BtnColor="#787878"
                    style={{ width: "100px", height: "40px" }}
                    onPress={(e) => {
                        history.push(`/auto_ventas/${empresa}/ingresos`);
                    }}
                >
                    Regresar
                </GenBtn>
            </div>

            <LoadMask loading={loadingState} color="#bd0034" blur={true}>
                <form
                    encType="multipart/form-data"
                    onSubmit={handleSubmit(() => {})}
                    className="form-container"
                >
                    <div className="propio-data-container">
                        <div className="modalidad-imagen-container">
                            <Controller
                                name="foto"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FicImageDrop
                                        value={value}
                                        handleFileChange={onChange}
                                        isDisabled={true}
                                    />
                                )}
                            />
                            <div className="modalidad-radio-container">
                                <div className="modalidad-radio-title">
                                    Modalidad
                                </div>
                                <FicRadio
                                    control={control}
                                    name="modalidad"
                                    isDisabled={true}
                                >
                                    <Radio value="PROPIO">Propio</Radio>
                                    <Radio value="CONSIGNACION">
                                        Consignación
                                    </Radio>
                                </FicRadio>
                            </div>
                        </div>
                        <div className="inputs-container">
                            <FicInput
                                label="Codigo"
                                name="codigo"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Marca"
                                name="marca"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Linea"
                                name="linea"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Tipo de carro"
                                name="tipo_carro"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Combustible"
                                name="combustible"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Modelo"
                                name="modelo"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Numero de serie"
                                name="num_serie"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Placa"
                                name="placa"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Motor"
                                name="motor"
                                control={control}
                                isDisabled={true}
                            />
                            <FicNumber
                                formatOptions={{
                                    signDisplay: "exceptZero",
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2,
                                }}
                                label="Millas"
                                name="millas"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Numero de VIN"
                                name="num_vin"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Numero de chasis"
                                name="num_chasis"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Numero de motor"
                                name="num_motor"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Color"
                                name="color"
                                control={control}
                                isDisabled={true}
                            />
                            <FicNumber
                                formatOptions={{
                                    signDisplay: "exceptZero",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }}
                                label="Costo del vehiculo"
                                name="costo_vehiculo"
                                control={control}
                                isDisabled={true}
                            />
                        </div>
                    </div>
                    {datos.proveedor_autoventa === 'S' &&
                        <div className="propio-data-container">
                            <div className="modalidad-imagen-container">
                            </div>
                            <div className="inputs-container">
                                <FicDatePicker
                                    label="Fecha de ingreso"
                                    name="fecha_ingreso"
                                    control={control}
                                    isDisabled={true}
                                />
                                <FicInput
                                    label="Proveedor"
                                    name="proveedor"
                                    control={control}
                                    isDisabled={true}
                                />
                                <FicInput
                                    label="Nombre valuador"
                                    name="nombre_valuador"
                                    control={control}
                                    isDisabled={true}
                                />
                            </div>
                        </div>
                    }
                    {modalidad == "CONSIGNACION" && (
                        <div className="consignacion-data-container">
                            <div className="datos-proveedor-container">
                                <h4>Datos del proveedor</h4>
                                <div className="datos-proveedor">
                                    <FicInput
                                        label="Nombre del proveedor"
                                        name="nombre_proveedor"
                                        control={control}
                                        isDisabled={true}
                                    />
                                    <FicInput
                                        label="Numero de telefono del proveedor"
                                        name="num_telefono_proveedor"
                                        control={control}
                                        isDisabled={true}
                                    />
                                </div>
                            </div>
                            <div className="data-wrapper">
                                <div className="c-card-container">
                                    <div className="c-card">
                                        <div className="c-card-header">
                                            DOCUMENTOS
                                        </div>
                                        <div className="c-card-body">
                                            <FicCheckbox
                                                control={control}
                                                name="calcomania"
                                                label="Calcomania"
                                                isDisabled={true}
                                            />
                                            <FicCheckbox
                                                control={control}
                                                name="titulo_propiedad"
                                                label="Titulo propiedad"
                                                isDisabled={true}
                                            />
                                            <FicCheckbox
                                                control={control}
                                                name="tajeta_circulacion"
                                                label="Tarjeta de circulacion"
                                                isDisabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="c-card">
                                        <div className="c-card-header">
                                            EQUIPO DE EMERGENCIAS
                                        </div>
                                        <div className="c-card-body">
                                            <FicCheckbox
                                                control={control}
                                                name="botiquin"
                                                label="Botiquín"
                                                isDisabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="c-card">
                                        <div className="c-card-header">
                                            HERRAMIENTAS
                                        </div>
                                        <div className="c-card-body">
                                            <FicCheckbox
                                                control={control}
                                                name="triangulos_reflectores"
                                                label="Triangulos reflectores"
                                                isDisabled={true}
                                            />
                                            <FicCheckbox
                                                control={control}
                                                name="triket"
                                                label="Triket"
                                                isDisabled={true}
                                            />
                                            <FicCheckbox
                                                control={control}
                                                name="caja_de_herramientas"
                                                label="Caja de herramientas"
                                                isDisabled={true}
                                            />
                                            <FicCheckbox
                                                control={control}
                                                name="llave_chuchos"
                                                label="Llave de chuchos o cruz"
                                                isDisabled={true}
                                            />
                                            <FicCheckbox
                                                control={control}
                                                name="llanta_de_repuesto"
                                                label="Llanta de repuesto"
                                                isDisabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="observaciones-container">
                                    <h4>Observaciones</h4>
                                    <FicTextArea
                                        textAreaStyle={{ height: "350px" }}
                                        name="observaciones"
                                        control={control}
                                        isDisabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </form>
            </LoadMask>
        </React.Fragment>
    );
}

export default withRouter(IngresosFormVer);
