import React, { useEffect, useRef, useState } from 'react';
import FormDialogModal from '../../components/FormDialogModal';
import { FicInput } from '../../Utils/FormInputComponents';
import DataTableWSearch from '../../components/DataTableWSearch';
import * as yup from "yup"
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import GenBtn from '../../components/GenBtn';
import * as Fa from 'react-icons/fa';

const schema = yup.object({
    nombre: yup.string().required('Nombre de proveedor es requerido'),
})


function ProveedoresList(props) {

    const { handleSubmit, reset, control, formState: { errors }} = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            nombre: '',
            telefono: '',
            correo: '',
            contacto: ''
        }    
    })
    const { handleSubmit: editSubmit, reset: editReset, control: editControl, formState: { errors: editErrors }} = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            nombre: "",
            telefono: "",
            correo: "",
            contacto: ""
        }    
    })

    const {crearProveedor, listarProveedores, editarProveedor, data } = props;
    const [ nombreFieldData, setNombreFieldData ] = useState()
    const [ telefonoFieldData, setTelefonoFieldData ] = useState()
    const [ correoFieldData, setCorreoFieldData ] = useState()
    const [ contactoFieldData, setContactoFieldData ] = useState()
    const [ proveedorId, setProveedorId ] = useState()

    const dialogRef = useRef(null)
    const editDialogRef = useRef(null)
    
    async function onSubmit(formData) {
        await crearProveedor(formData);
        reset();
        listarProveedores();
    }

    async function onEditSubmit(formData) {
        await editarProveedor(formData, proveedorId);
        listarProveedores();
        editDialogRef.current.close()
        editReset();
    }

    useEffect(() => {
        listarProveedores();
    }, [])

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.nombre,
            sortable: true,
        },
        {
            name: 'Telefono',
            selector: row => row.telefono,
            sortable: true,
        },
        {
            name: 'Correo',
            selector: row => row.correo,
            sortable: true,
        },
        {
            cell: (row) => <GenBtn key={row.nombre} textColor="hsl(0, 0%, 80%)" background="hsla(0, 100%, 100%, 0.2)" 
                                onPress={
                                (e) => {
                                    editDialogRef.current.showModal() 
                                    setNombreFieldData(row.nombre)
                                    setTelefonoFieldData(row.telefono)
                                    setCorreoFieldData(row.correo)
                                    setContactoFieldData(row.contacto)
                                    setProveedorId(row.id)
                                }}>
                                <Fa.FaPen/>
                            </GenBtn>
                ,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return(
        <React.Fragment>

            <br />
            <h3 className="p-2  title rounded shadow"
                style={{border: "1px solid #790021", background: "linear-gradient(to top right, #790021, #2d2d2d)"}}
            >
                Proveedores
            </h3>
            <br />
            <div className="d-flex flex-row justify-content-start mb-2">
            <GenBtn
                onClick={() => {
                    dialogRef.current.showModal()
                    }}>
                Agregar
            </GenBtn>

            </div>

            <DataTableWSearch 
                columns={columns} 
                data={data} 
                fieldToFilter="nombre" 
                filterPlaceHolder="Buscar por nombre"
            />

            <FormDialogModal dialogRef={dialogRef} onSubmit={handleSubmit(onSubmit)} title={"Agregar proveedor"}>
                <div style={{display:"flex", flexDirection: "column", gap: "15px", width:"25rem", height:"20rem", padding: "15px"}}>
                    <FicInput
                        name="nombre"
                        placeholder="Nombre proveedor"
                        control={control}
                        error={errors && errors.nombre && errors.nombre.message}                   
                    />
                    <FicInput
                        name="telefono"
                        placeholder="Telefono"
                        control={control}
                    />
                    <FicInput
                        name="correo"
                        placeholder="Correo"
                        control={control}
                    />
                    <FicInput
                        name="contacto"
                        placeholder="Contacto"
                        control={control}
                    />
                    <div className='d-flex justify-content-end mt-auto'>
                        <GenBtn 
                            type="submit"
                            style={{
                                width:"90px", 
                                height:"40px", 
                                marginBottom:"8px"}}>
                            Agregar
                        </GenBtn>
                    </div>
                </div>

            </FormDialogModal>

            <FormDialogModal dialogRef={editDialogRef} onSubmit={editSubmit(onEditSubmit)} title={"Modificar proveedor"}>
                <div style={{display:"flex", flexDirection: "column", gap: "15px", width:"25rem", height:"20rem", padding: "15px"}}>
                    <FicInput
                        name="nombre"
                        placeholder={`${nombreFieldData}`}
                        control={editControl}
                        error={editErrors && editErrors.nombre && editErrors.nombre.message}
                    />
                    <FicInput
                        name="telefono"
                        placeholder={`${telefonoFieldData}`}
                        control={editControl}
                    />
                    <FicInput
                        name="correo"
                        placeholder={`${correoFieldData}`}
                        control={editControl}
                    />
                    <FicInput
                        name="contacto"
                        placeholder={`${contactoFieldData}`}
                        control={editControl}
                    />
                    <div className='d-flex justify-content-end mt-auto'>
                        <GenBtn 
                            type="submit"
                            style={{
                                width:"90px", 
                                height:"40px", 
                                marginBottom:"8px"}}>
                            Guardar
                        </GenBtn>
                    </div>
                </div>

            </FormDialogModal>
            
        </React.Fragment>
    );

}

export default ProveedoresList;

