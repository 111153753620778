import { handleActions, createAction } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const ListarTipoGastoAction = createAction("LISTAR_GASTOS")

export const crearTipoGasto = (data) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const formData = {
        empresa: id_emp[5],
        descripcion: data.descripcion,
    }
    return api.post('auto_ventas/tipo_gasto', formData).then(() => {
        NotificationManager.success(
            'Tipo gasto creado',
            'Exito',
            3000
        );
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    })
}

export const editarTipoGasto = (data, gastoId) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const formData = {
        empresa: id_emp[5],
        descripcion: data.descripcion,
    }
    return api.put(`auto_ventas/tipo_gasto/${gastoId}`, formData).then(() => {
        NotificationManager.success(
            'Tipo gasto modificado',
            'Exito',
            3000
        );
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    })
}

export const listarGastos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    return api.get('auto_ventas/tipo_gasto', params).then(response => {
        dispatch(ListarTipoGastoAction(response));
    }).catch((error) => {
        return [];
    })
}

export const actions = {
    crearTipoGasto,
    listarGastos,
    editarTipoGasto
};

export const reducers = {
    [ListarTipoGastoAction]: (state, action) => {
        return {
            ...state,
            data: action.payload
        }
    },
};

export const initialState = {
    data: [],
};

export default handleActions(reducers, initialState)