import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import * as FileSaver from 'file-saver';
import * as XLSX from 'sheetjs-style';
import Swal from 'sweetalert2';

const LOADER = 'LOADER';
const MODULO = 'MODULO';
const LOADERC = 'LOADERC';
const LISTADO_MONEDAS = 'LISTADO_MONEDAS';
const LISTADO_PERIODOS = 'LISTADO_PERIODOS';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderC = loaderC => ({
    type: LOADERC,
    loaderC,
});

export const listarCuentas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/cuentas/empresa_cuentas/', params).then(data => {
        const list_cuentas = [];
        if (data) {
            data.forEach(item => {
                if (item.tipo === 'A') {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre,
                        isDisabled: true
                    })
                } else {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre
                    })
                }
            })
        }
        return list_cuentas;
    }).catch((error) => {
        
        return [];
    })
}

export const listarCC = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/centrocosto/empresa_centro_costo/', params).then(data => {
        const list_cc = [];
        if (data) {
            data.forEach(item => {
                list_cc.push({
                    value: item.id,
                    label: item.codigo + ' ' + item.descripcion
                })
            })
        }
        return list_cc;
    }).catch((error) => {
        
        return [];
    })
}


export const listarMonedas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/monedas/moneda_empresa', params).then((response) => {
        const moneda = [];
        response.forEach(item => {
            moneda.push({
                value: item.id,
                label: item.moneda + ' - ' + item.simbolo
            })
        })
        dispatch({ type: LISTADO_MONEDAS, monedas: moneda });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar monedas',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const reporteKardexValorizado = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesPrd.values;
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let moneda = data.moneda;
    let tipo = data.tipoCliente
    let reporte = 'kardex_valorizado';
    let producto = data.producto ? data.producto.value : '';
    let modulo = 'AGR';
    let bodega = data.bodega ? data.bodega.value : '';
    const params = { id, reporte, fechaI, fechaF, moneda, tipo, producto, modulo, bodega };
    dispatch(setLoader(true));
    api.getPdf('/movimiento_inventario', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_kardex_valorizado.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                error.body.msj,
                'Informacion',
                5000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarProductos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/producto/empresa_productos_cod',params).then(data=>{
        const list_producto = [];
        if(data){
            data.forEach(item=>{
                if (item.tipo === 'P'){
                    list_producto.push({
                        value: item.id,
                        label: item.SKU + ' / ' + item.producto
                    })
                }
            })
        }
        return list_producto ;
    }).catch((error)=>{
        return [];
    })
} 

export const listarFases = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/fase_prd/buscar_fase', params).then(data => {
        const list_fase = [];
        if (data) {
            data.forEach(item => {
                list_fase.push({
                    value: item.id,
                    label: item.descripcion
                })
            })
        }
        return list_fase;
    }).catch((error) => {
        return [];
    })
}

export const reporteProduccionDetallePro = (sel) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesPrd.values;
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let reporte = 'produccionDetalle';
    let producto = data.producto ? data.producto.value : '';
    let llave = data.fase ? data.fase.value : '';
    const params = { id, reporte, fechaI, fechaF, producto, sel, llave };
    
    dispatch(setLoader(true));
    api.getPdf('/ingreso_produccion', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_produccion_detalle.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                error.body.msj,
                'Informacion',
                5000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarClientes = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/cliente/empresa_clientes',params).then(data=>{
        const list_cliente = [];
        if(data){
            data.forEach(item=>{
                list_cliente.push({
                    value: item.id,
                    label: item.identificador + ' ' + item.nombre
                })
            })
        }
        return list_cliente ;
    }).catch((error)=>{
        
        return [];
    })
}

export const reporteProduccionTrazPro = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesPrd.values;
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let reporte = 'produccionTrazabilidad';
    let cliente = data.cliente ? data.cliente.value : '';
    const params = { id, reporte, fechaI, fechaF, cliente };
    
    dispatch(setLoader(true));
    api.getPdf('/ingreso_produccion', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_trazabilidad_produccion.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                error.body.msj,
                'Informacion',
                5000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const excelReporteProduccionDetalle = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesPrd.values;
    
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let reporte = 'excelProduccionDetalle';
    let producto = data.producto ? data.producto.value : '';
    let llave = data.fase ? data.fase.value : '';
    const params = { id, reporte, fechaI, fechaF, producto, llave };
    
    dispatch(setLoader(true));
    api.get('/ingreso_produccion', params).then((response) => {
        
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const ws = XLSX.utils.json_to_sheet(response);
        const wb = { Sheets: { 'Detalle produccion': ws }, SheetNames:['Detalle produccion']};
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array"});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, "detalle_produccion"+".xlsx")
        
    }).catch((error) => {
        if (error.status === 404){
            NotificationManager.info(
              error.body.msj,
              'Informacion',
              5000
            );
        }
        if (error.status === 500){
            NotificationManager.error(
              'Ocurrió un error al generar reporte',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const reporteConsumos = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesPrd.values;
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let bodega = data.bodega ? data.bodega.value : '';
    let reporte = 'consumo';
    const params = { id, reporte, fechaI, fechaF, bodega };
    
    dispatch(setLoader(true));
    api.getPdf('/ingreso_produccion', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_trazabilidad_produccion.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                error.body.msj,
                'Informacion',
                5000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const reporteIngresosProdDetalle= () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesPrd.values;
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let serie = data.serie ? data.serie.label : '';
    const params = { id, fechaI, fechaF, serie};
    dispatch(setLoader(true));
    api.getPdf('/ingreso_produccion_detalle/reporte', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_ingresos_de_prod_detalle.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                error.body.msj,
                'Informacion',
                5000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarSeries = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let tipo = 'CON';
    const params = { id, search, tipo }
    return api.get('/serie_prd', params).then(data => {
        const list_serie = [];
        if (data) {
            data.forEach(item => {
                list_serie.push({
                    value: item.id,
                    label: item.serie
                })
            })
        }
        return list_serie;
    }).catch((error) => {
        return [];
    })
}

export const listarSeriesProd = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let tipo = 'ING';
    const params = { id, search, tipo }
    return api.get('/serie_prd', params).then(data => {
        const list_serie = [];
        if (data) {
            data.forEach(item => {
                list_serie.push({
                    value: item.id,
                    label: item.serie
                })
            })
        }
        return list_serie;
    }).catch((error) => {
        return [];
    })
}
export const listarSeriesNotaCobro = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let tipo = 'NTA';
    const params = { id, search, tipo }
    return api.get('/serie_prd', params).then(data => {
        const list_serie = [];
        if (data) {
            data.forEach(item => {
                list_serie.push({
                    value: item.id,
                    label: item.serie
                })
            })
        }
        return list_serie;
    }).catch((error) => {
        return [];
    })
}

export const listarBodegas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search };
    return api.get('/bodega', params).then((response) => {
        const bodega = [];
        if (response){
            response.forEach(item=>{
                bodega.push({
                    value: item.id,
                    label: item.numero + ' / ' + item.bodega
                })
            })
        }
        return bodega;
    }).catch((error) => {
        return [];
    })
}

export const listarPilotos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/piloto/buscar_piloto', params).then(data => {
        const list_ing = [];
        if (data) {
            data.forEach(item => {
                list_ing.push({
                    value: item.id,
                    label: item.nombre
                })
            })
        }
        return list_ing;
    }).catch((error) => {
        return [];
    })
}

export const reporteControlCajasPiloto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesPrd.values;
    let fechaF = data.fechaFinal;
    let piloto = data.piloto ? data.piloto.value : '';
    let reporte = 'control_cajas_piloto';
    const params = { id, reporte, fechaF, piloto };
    dispatch(setLoader(true));
    api.getPdf('/movimiento_insumos', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_control_cajas.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                error.body.msj,
                'Informacion',
                5000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarInsumos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');                                   
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/insumo_prd/buscar_insumo', params).then(data => {
        const list_insumo = [];
        if (data) {
            data.forEach(item => {
                list_insumo.push({
                    value: item.id,
                    label: item.descripcion
                })
            })
        }
        return list_insumo;
    }).catch((error) => {
        return [];
    })
}

export const reporteKardexCajas = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesPrd.values;
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let reporte = 'kardex_cajas';
    let insumo = data.insumo ? data.insumo.value : '';
    const params = { id, reporte, fechaI, fechaF, insumo };
    dispatch(setLoader(true));
    api.getPdf('/movimiento_insumos', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_existencia_cajas.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                error.body.msj,
                'Informacion',
                5000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const reporteExistenciaCajasExcel = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesPrd.values;
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let reporte = 'kardex_cajas_excel';
    let insumo = data.insumo ? data.insumo.value : '';
    const params = { id, reporte, fechaI, fechaF, insumo };
    dispatch(setLoader(true));
    api.getExcel('/movimiento_insumos', params).then((response) => {
        const blob = response;
        const fileName = 'kardex_de_cajas.xlsx';

        // Descargar el archivo utilizando la función saveAs de file-saver
        const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(file, fileName);

    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                error.body.msj,
                'Informacion',
                5000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const reporteListadoNotasDeCobro = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesPrd.values;
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let reporte = 'listado_notas_de_cobro';
    let serie = data.serie ? data.serie.label : '';
    const params = { id, reporte, fechaI, fechaF, serie };
    dispatch(setLoader(true));
    api.getPdf('/nota_cobro_produccion', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'listado_de_notas_de_cobro.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                error.body.msj,
                'Informacion',
                5000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const excelReporteConsumos = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesPrd.values;
    
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let bodega = data.bodega ? data.bodega.value : '';
    let reporte = 'excelConsumo';
    const params = { id, reporte, fechaI, fechaF, bodega };

    dispatch(setLoader(true));
    api.getExcel('/ingreso_produccion', params).then((response) => {
        
        const blob = response;
        const fileName = 'consumo_produccion.xlsx';

        // Descargar el archivo utilizando la función saveAs de file-saver
        const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(file, fileName);
        
    }).catch((error) => {
        if (error === 404){
            NotificationManager.info(
              'No hay datos para mostrar',
              'Informacion',
              5000
            );
        }
        if (error === 500){
            NotificationManager.error(
              'Ocurrió un error al generar reporte',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const reporteExistenciaCajas = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesPrd.values;
    let fechaF = data.fechaFinal;
    let reporte = 'existencia_cajas';
    const params = { id, reporte, fechaF };
    dispatch(setLoader(true));
    api.getPdf('/movimiento_insumos', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_existencia_cajas.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                error.body.msj,
                'Informacion',
                5000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const reporteCosechas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let reporte = 'cosechas';
    const params = { id, reporte };
    dispatch(setLoaderC(true));
    api.getPdf('/siembra', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'cosechas.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                error.body.msj,
                'Informacion',
                5000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }

    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}

export const reporteCXCDetalleEnvio = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesPrd.values;
    
    if (data === undefined){
        Swal.fire({
            title: 'Error!',
            text: 'Debe seleccionar un cliente',
            type: 'error',
        })
    } else {
        if (data.fechaFinal === undefined || data.fechaFinal === ''){
            Swal.fire({
                title: 'Error!',
                text: 'Debe seleccionar una fecha',
                type: 'error',
            })
        }else {
            let reporte = 'detalleEnvioCliente';
            let cliente = data.cliente.value;
            let fecha = data.fechaFinal;
            let moneda = data.moneda;
            const params = { id, reporte, cliente, moneda, fecha };
            dispatch(setLoader(true));
            api.getPdf('/envio_produccion', params).then((response) => {
                if (response.headers['content-type'] == 'application/pdf') {
                    var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'detalle_envio.pdf';
                    window.open(link, '_blank');
                    return false;
                }
            }).catch((error) => {
                if (error.status === 404) {
                    NotificationManager.info(
                        error.body.msj,
                        'Informacion',
                        5000
                    );
                }
                if (error.status === 500) {
                    NotificationManager.error(
                        'Ocurrió un error al generar reporte',
                        'Error',
                        0
                    );
                }

            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
    
}

export const listarPeriodos = (year) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id, year };
    dispatch(setLoader(true));
    api.get('/periodos/periodo_empresa_year', params).then((response)=>{
        let periodo = [];
        response.forEach(item=>{
            periodo.push({
                value: item.id,
                label: item.periodo
            })
        })
        dispatch({ type: LISTADO_PERIODOS, listaPeriodo: periodo });
    }).catch(()=>{
        NotificationManager.error(
            'Ocurrió un error al listar los periodos',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const reporteCostoLibra = () => (dispatch, getStore) => {
    const data = getStore().form.FormInformesPrd.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    let id = id_emp[5];
    let params = {};
    let mes = data.periodo;
    let year = data.anio;

    let reporte = 'reporteCostoLibra';
    params = { id, reporte, mes, year };
    
    dispatch(setLoader(true));
    api.getExcel('/ingreso_produccion', params).then((response) => {
        const blob = response;
        const fileName = 'costo_libra.xlsx';

        // Descargar el archivo utilizando la función saveAs de file-saver
        const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(file, fileName);
        
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                error.body.msj,
                'Error',
                3000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const excelReporteControlCajasPiloto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesPrd.values;
    let fechaF = data.fechaFinal;
    let piloto = data.piloto ? data.piloto.value : '';
    let reporte = 'excel_control_cajas_piloto';
    const params = { id, reporte, fechaF, piloto };
    dispatch(setLoader(true));
    api.getExcel('/movimiento_insumos', params).then((response) => {
        const blob = response;
        const fileName = 'kardex_de_cajas.xlsx';

        // Descargar el archivo utilizando la función saveAs de file-saver
        const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(file, fileName);
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                error.body.msj,
                'Informacion',
                5000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const reporteVentaFase = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesPrd.values;
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let reporte = 'produccionVentaFase';
    let producto = data.producto ? data.producto.value : '';
    const params = { id, reporte, fechaI, fechaF, producto };
    
    dispatch(setLoader(true));
    api.getPdf('/ingreso_produccion', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_produccion_detalle.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                error.body.msj,
                'Informacion',
                5000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });
}



export const actions = {
    listarCuentas,
    listarCC,
    listarMonedas,
    listarFases,
    listarProductos,
    listarClientes,
    listarSeries,
    listarSeriesProd,
    listarBodegas,
    listarSeriesNotaCobro,
    listarPilotos,
    listarInsumos,
    getModulo,
    reporteKardexValorizado,
    reporteProduccionDetallePro,
    reporteProduccionTrazPro,
    reporteConsumos,
    reporteIngresosProdDetalle,
    reporteControlCajasPiloto,
    reporteKardexCajas,
    reporteExistenciaCajas,
    reporteListadoNotasDeCobro,
    excelReporteProduccionDetalle,
    excelReporteConsumos,
    reporteExistenciaCajasExcel,
    reporteCosechas,
    reporteCXCDetalleEnvio,
    listarMonedas,
    listarPeriodos,
    reporteCostoLibra,
    excelReporteControlCajasPiloto,
    reporteVentaFase
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [LOADERC]: (state, { loaderC }) => {
        return {
            ...state,
            loaderC,
        };
    },
    [LISTADO_MONEDAS]: (state, { monedas }) => {
        return {
            ...state,
            monedas,
        };
    },
    [LISTADO_PERIODOS]: (state, { listaPeriodo }) => {
        return {
            ...state,
            listaPeriodo,
        };
    },
};

export const initialState = {
    loader: false,
    monedas: [],
    modulo: [],
    loaderC: false,
    monedas: [],
    listaPeriodo: []
};

export default handleActions(reducers, initialState)
