import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/auto_ventas/tipoGasto';
import TipoGastoList from './tipoGastoList';


const ms2p = (state) => {
  return {
    ...state.auto_ventas_tipo_gasto,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(TipoGastoList);
